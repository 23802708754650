// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-contacts-jsx": () => import("./../../../src/pages/contacts.jsx" /* webpackChunkName: "component---src-pages-contacts-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-internal-seminars-jsx": () => import("./../../../src/pages/internal-seminars.jsx" /* webpackChunkName: "component---src-pages-internal-seminars-jsx" */),
  "component---src-pages-news-jsx": () => import("./../../../src/pages/news.jsx" /* webpackChunkName: "component---src-pages-news-jsx" */),
  "component---src-pages-public-outreach-jsx": () => import("./../../../src/pages/public-outreach.jsx" /* webpackChunkName: "component---src-pages-public-outreach-jsx" */),
  "component---src-pages-publications-jsx": () => import("./../../../src/pages/publications.jsx" /* webpackChunkName: "component---src-pages-publications-jsx" */),
  "component---src-pages-research-jsx": () => import("./../../../src/pages/research.jsx" /* webpackChunkName: "component---src-pages-research-jsx" */),
  "component---src-pages-resources-jsx": () => import("./../../../src/pages/resources.jsx" /* webpackChunkName: "component---src-pages-resources-jsx" */),
  "component---src-pages-the-group-jsx": () => import("./../../../src/pages/the-group.jsx" /* webpackChunkName: "component---src-pages-the-group-jsx" */),
  "component---src-templates-news-page-news-page-jsx": () => import("./../../../src/templates/NewsPage/NewsPage.jsx" /* webpackChunkName: "component---src-templates-news-page-news-page-jsx" */),
  "component---src-templates-projects-page-projects-page-jsx": () => import("./../../../src/templates/ProjectsPage/ProjectsPage.jsx" /* webpackChunkName: "component---src-templates-projects-page-projects-page-jsx" */),
  "component---src-templates-public-outreach-events-page-public-outreach-events-page-jsx": () => import("./../../../src/templates/PublicOutreachEventsPage/PublicOutreachEventsPage.jsx" /* webpackChunkName: "component---src-templates-public-outreach-events-page-public-outreach-events-page-jsx" */)
}

